<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2021-04-06 15:41:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="addCity box-create">
        <p style=""></p>
        <div class="box-title">{{$t('project.data.Battery-Compatibility.add')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="1">
                    <!-- <el-divider content-position="center">{{$t('project.regular-data')}}</el-divider> -->
                    
                    <el-form-item :label="$t('project.battery')"  prop="batteryId" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <!-- <el-input v-model="form.batteryId" ></el-input> -->
                        <el-select v-model="form.batteryId" filterable clearable>
                            <el-option
                                    v-for="item in batteryDataList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('project.battery-array')" prop="batteryNumArr" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.batteryNumArr" ></el-input>
                    </el-form-item>
                   <el-form-item :label="$t('subject.inverter')" prop="inverterType" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <!-- <el-input v-model="form.inverterType" ></el-input> -->
                        <el-select v-model="form.inverterType" filterable clearable>
                            <el-option
                                    v-for="item in modelSeriesList"
                                    :key="item.value"
                                    :label="item.code"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    
                     
                </el-col>
                
       
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminBatteryCompatibility'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        return {
            form:{},
            compatibleId: this.$route.query.id,
            defaultGridIDs:[5,6,13],
            modelSeriesList:[],
            batteryDataList:[]
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.compatibleId){
            this.form = JSON.parse(localStorage.getItem("compatibleId"))
        }
        
        this.tdictionaryGetDictionaryBatch()
        this.tbatteryListAll()
    },
    methods: {
        changeNomPowerform(){
            let nomPowerTo = this.form.nomPowerTo && parseInt(this.form.nomPowerTo)
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            this.form.nomPowerFrom = nomPowerFrom > nomPowerTo ? nomPowerTo - 1 :nomPowerFrom
        },
        changeNomPowerTo(){
            let nomPowerTo = this.form.nomPowerTo? parseInt(this.form.nomPowerTo) : ''
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            if((nomPowerTo <= nomPowerFrom) && nomPowerFrom && nomPowerTo){
                this.$message({
                    message: this.$t('nomPowerFrom.big'),
                    type: 'warning'
                });
                this.form.nomPowerTo = parseInt(nomPowerFrom) + 1
                nomPowerTo = parseInt(nomPowerFrom) + 1
            }
            if(nomPowerTo >150 || nomPowerTo  == 150){
                // console.log(this.form.nomPowerTo,'?');
                this.form.nomPowerTo = 149
            }else if(nomPowerTo <=100){
                this.form.nomPowerTo = 100
            }
        },
        /*获取详情数据*/
        async theatpumpGetById(){
            let res = await this.API.theatpumpGetById({
                id: this.compatibleId
            })

            if(this.defaultGridIDs.indexOf(res.defaultGridID)<0){
                res.defaultGridID = ''
            }
            this.form = res
        },

        async tdictionaryGetDictionaryBatch(){
            let res = await this.API.tdictionaryGetDictionaryBatch({
                dictCodes:'INVERTER_TYPES',
                lang:localStorage.getItem('lang')||'zh_CN'
            })
            this.modelSeriesList = res.INVERTER_TYPES
        },
        
      // 获取电池所有数据
        async tbatteryListAll(){
            let res = await this.API.tbatteryListAll()
            this.batteryDataList = res
        },

        /*保存提交*/
        saveForm(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if($this.compatibleId){
                        $this.update($this.form)
                    }else {
                        $this.save($this.form)
                    }
                } else {
                    return false;
                }
            });
        },

        /*更新*/
        async update(data){
            let res = await this.API.tBatterycompatibleUpdate(data)
            this.showMessage(res)

        },

        /*创建*/
        async save(data){
            let res = await this.API.tBatterycompatibleSave(data)
            this.showMessage(res)
        },

        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminBatteryCompatibility'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },


    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
    .el-form{
        .el-divider{
            white-space: nowrap;
        }
        .el-input-group__append, .el-input-group__prepend{
            padding: 0;
            width: 50px;
            text-align: center;
            font-size: 12px;
        }
        .el-input-group+.el-input-group{
            margin-left:10px;
        }
        .el-input-group.inline{
            width:112px;
        }
        .el-col-11,.el-col-2{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .el-col-2{
            text-align: center;
        }
    }
}
    
</style>

